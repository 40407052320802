import { FC } from 'react'
import { TestProps } from 'typ'
import { Button, ButtonProps } from '../button'

export type DialogButtonProps = TestProps &
  Pick<ButtonProps, 'onPress' | 'children' | 'isDisabled' | 'isLoading' | 'type'>
export type DialogConfirmButtonProps = DialogButtonProps & {
  color?: 'danger' | 'primary'
}

export const DialogCancelButton: FC<DialogButtonProps> = ({ children, ...props }) => (
  <Button variant="soft" color="neutral" size="md" {...props}>
    {children}
  </Button>
)

export const DialogConfirmButton: FC<DialogConfirmButtonProps> = ({
  children,
  color = 'primary',
  ...props
}) => (
  <Button variant="solid" color={color} size="md" {...props}>
    {children}
  </Button>
)
