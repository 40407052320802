import { forwardRef } from 'react'
import { useObjectRef } from 'react-aria'
import { getPreventEnterDefault } from '../../utils'
import { resizeHandleCx, textareaBoxCx, wrapperCx } from './TextareaBox.css'
import { TextareaBoxProps } from './types'
import { useCompositionHandlersProps } from './utils/useCompositionHandlers'

const TextareaResizeHandle = () => (
  <div className={resizeHandleCx} data-testid="textarea-resize-handle">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 10.5L9.5 4" stroke="#8D8F97" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8 11L10.5 8.5" stroke="#8D8F97" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  </div>
)
export const TextareaBox = forwardRef<HTMLTextAreaElement, TextareaBoxProps>(
  (
    {
      preventEnterDefault,
      hasCharacterCount,
      hasResizeHandle = false,
      size = 'md',
      rows = 2,
      isDisabled,
      setCharCount,
      ...props
    },
    forwardedRef
  ) => {
    const ref = useObjectRef(forwardedRef)
    const { isComposing, ...restProps } = useCompositionHandlersProps<TextareaBoxProps>(props)
    const { maxLength } = restProps
    const isCharCountVisible = hasCharacterCount && Boolean(maxLength) && setCharCount

    const handleKeyPressBehavior = getPreventEnterDefault(
      preventEnterDefault || isComposing,
      props.onKeyPress
    )

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (isCharCountVisible) {
        setCharCount(e.target.value.length)
      }
      props.onChange?.(e)
    }

    return (
      <div className={wrapperCx}>
        {hasResizeHandle && <TextareaResizeHandle />}
        <textarea
          {...restProps}
          className={textareaBoxCx({ hasResizeHandle, size })}
          onChange={handleChange}
          onKeyPress={handleKeyPressBehavior}
          ref={ref}
          disabled={isDisabled}
          rows={rows}
        />
      </div>
    )
  }
)

TextareaBox.displayName = 'TextareaBox'
