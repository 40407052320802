import { addDays } from 'date-fns'
import { getCurrentJpDayStart } from 'datetime'
import { StoreSlice } from '../types'

export interface EditBatteryScheduleSlice {
  editBatteryScheduleStepIndex: number
  editBatteryScheduleIsConsentAgreed: boolean
  editBatteryScheduleBatteryChargeData: number[]
  editBatteryScheduleDate: Date
  setEditBatteryScheduleDate: (date: Date) => void
  toggleEditBatteryScheduleConsentAgreement: () => void
  toEditBatteryScheduleNextStep: () => void
  toEditBatterySchedulePrevStep: () => void
  toEditBatteryScheduleStep: (index: number) => void
  resetEditBatterySchedule: () => void
  updateEditBatteryScheduleBatteryChargeData: (index: number, value: number) => void
  setEditBatteryScheduleBatteryChargeData: (values: number[]) => void
}

const getInitialState = () => ({
  editBatteryScheduleDate: addDays(getCurrentJpDayStart(), 1),
  editBatteryScheduleStepIndex: 0,
  editBatteryScheduleIsConsentAgreed: false,
  editBatteryScheduleBatteryChargeData: new Array(48).fill(0),
})

export const createEditBatteryScheduleSlice: StoreSlice<EditBatteryScheduleSlice> = (
  set,
  _get
) => ({
  ...getInitialState(),
  setEditBatteryScheduleDate: (date: Date) => set({ editBatteryScheduleDate: date }),
  toggleEditBatteryScheduleConsentAgreement: () =>
    set((state) => ({
      editBatteryScheduleIsConsentAgreed: !state.editBatteryScheduleIsConsentAgreed,
    })),
  toEditBatteryScheduleStep: (index: number) =>
    set({ editBatteryScheduleStepIndex: Math.max(Math.min(index, 1), 0) }),
  toEditBatteryScheduleNextStep: () =>
    set((state) => ({
      editBatteryScheduleStepIndex: Math.min(state.editBatteryScheduleStepIndex + 1, 1),
    })),
  toEditBatterySchedulePrevStep: () =>
    set((state) => ({
      editBatteryScheduleStepIndex: Math.max(state.editBatteryScheduleStepIndex - 1, 0),
    })),
  resetEditBatterySchedule: () => set(getInitialState()),
  updateEditBatteryScheduleBatteryChargeData: (index: number, value: number) =>
    set((state) => {
      const newBatteryChargeData = [...state.editBatteryScheduleBatteryChargeData]
      newBatteryChargeData[index] = value
      return {
        editBatteryScheduleBatteryChargeData: newBatteryChargeData,
      }
    }),
  setEditBatteryScheduleBatteryChargeData: (values: number[]) =>
    set({ editBatteryScheduleBatteryChargeData: values }),
})
