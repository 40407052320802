import { TableLayout } from 'typ'
import { StoreSlice } from '../types'

export type ScenariosTableField =
  | 'readableId'
  | 'name'
  | 'description'
  | 'updatedAt'
  | 'createdAt'

const initialLayout: TableLayout<ScenariosTableField> = {
  sortParam: 'createdAt',
  sortAscending: false,
  displayedCols: [
    'readableId',
    'name',
    'description',
    'updatedAt',
    'createdAt',
  ],
}

export interface ScenariosListSlice {
  // TABLE LAYOUT
  scenariosListLayout: TableLayout<ScenariosTableField>
  setScenariosListLayout: (
    layout: Partial<TableLayout<ScenariosTableField>>
  ) => void
}

export const createScenariosListSlice: StoreSlice<ScenariosListSlice> = (
  set,
  _get
) => ({
  scenariosListLayout: {
    ...initialLayout,
  },
  setScenariosListLayout: (layout: Partial<TableLayout<ScenariosTableField>>) =>
    set((state) => ({
      scenariosListLayout: { ...state.scenariosListLayout, ...layout },
    })),
})
