import { useTensorCloudStore } from './tc-fe'

export {
  INITIAL_SPV_INVESTMENT_VALUE,
  INITIAL_SPV_LOAN_VALUE,
  getApiRegionCodeLabelMap,
  getEnergyConsumptionCodeLabelMap,
  getEnergySourceCodeLabelMap,
} from './slices'

export type {
  AssetTableField,
  BalancingGroupsTableField,
  PpaTableField,
  ReportsTableField,
  ScenariosTableField,
  SpvsTableField,
} from './slices'

export {
  ANALYTICS_MARKETS_TABS,
  ANALYTICS_SUPPLY_DEMAND_TABS,
  CASH_FLOW_AGGREGATION_KEYS,
  CASH_FLOW_BREAKDOWN_KEYS,
  CASH_FLOW_KEYS,
  ENERGY_CONSUMPTION_API_CODES,
  ENERGY_CONSUMPTION_COLOR_MAP,
  ENERGY_SOURCE_API_CODES,
  ENERGY_SOURCE_COLOR_MAP,
  GENERATION_KEYS,
  INTRADAY_CHART_SELECTION,
  INTRADAY_DATA_OPTIONS,
  M_Y_RESOLUTION_KEYS,
  NEW_BG_CREATION_STEPS,
  NEW_PPA_CREATION_STEPS,
  NEW_SPV_CREATION_STEPS,
  OPEX_KEYS,
  PAGINATED_LIST_SIZES,
  REGIONS_COLOR_MAP,
  REGION_API_CODES,
  REGION_API_CODES_ALPHABETICAL,
  REVENUES_KEYS,
  SETTINGS_STAKEHOLDERS_TABS,
  SOLAR_ASSET_TABS,
  SPV_ASSETS_FORM_INITIAL_VALUES,
  SPV_FINANCIAL_INFORMATION_FORM_INITIAL_VALUES,
  SPV_LEGAL_ENTITY_FORM_INITIAL_VALUES,
} from './constants'

export type {
  AnalyticsMarketsTab,
  AnalyticsSupplyDemandTab,
  BgCreationFormValues,
  BgFormValues,
  CashFlowAggregation,
  CashFlowBreakdownKey,
  CashFlowBreakdownUnit,
  CashFlowKey,
  DayAheadVolume,
  EnergyConsumptionApiCode,
  EnergySourceApiCode,
  GenerationKey,
  IntradayChartSelection,
  IntradayDataOption,
  MYResolution,
  NewBgCreationStepId,
  NewPpaCreationStepId,
  NewSpvCreationStepId,
  OpexKey,
  PaginatedListSize,
  PpaCreationFormValues,
  PpaFormValues,
  RegionApiCode,
  RevenuesAggregation,
  RevenuesKey,
  SettingsStakeholdersTab,
  SolarAssetTab,
  SpvAssetsFormValues,
  SpvEditFormValues,
  SpvFinancialFormValues,
  SpvFormValues,
  SpvInvestmentFormInput,
  SpvInvestmentFormValues,
  SpvLegalEntityFormValues,
  SpvLoanFormInput,
  SpvLoanFormValues,
  SupplyDemandUnit,
} from './types'

export { useAdminSessionStore as tensorAdminSessionStore, useAdminStore } from './ta-fe'

export { useTensorCloudSessionStore as tensorCloudSessionStore } from './tc-fe'

export default useTensorCloudStore
