import { getMaxNumUnit, getLongFormSuffix } from './utils'
import { currencySuffixMap } from './lists'
import { NumberFormatUnit } from './types'

export const getParsedMaxNumUnit = (
  max: number,
  unit: NumberFormatUnit = 'int',
  locale: string = 'en'
) => {
  const { suffix } = getMaxNumUnit(max, unit, locale)
  const isCurrency = ['JPY', 'USD'].includes(unit)
  switch (true) {
    case locale === 'ja' && isCurrency:
      return `${suffix}${currencySuffixMap[unit as 'JPY' | 'USD']}`
    case isCurrency && Boolean(suffix):
      return `${getLongFormSuffix(suffix)} ${unit}`
    case isCurrency:
      return ` ${unit}`
    default:
      return `${suffix}`
  }
}
