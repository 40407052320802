import {
  AssetTableField,
  BalancingGroupsTableField,
  PpaTableField,
  ReportsTableField,
  SpvsTableField,
} from '../slices'

export const tensorCloudMigrations = [
  // MIGRATION FROM VERSION 0 TO 1
  (state: any) => state,
  // MIGRATION FROM VERSION 1 TO 2
  (state: any) => {
    const balancingGroupListDefaultCols: BalancingGroupsTableField[] = [
      'bgCode',
      'name',
      'gridZone',
      'totalAssets',
      'updatedAt',
    ]
    const reportsListDefaultCols: ReportsTableField[] = ['name', 'scope', 'createdAt', 'author']

    const solarAssetsDefaultCols: AssetTableField[] = [
      'name',
      'readableId',
      'acCapacity',
      'locationName',
      'simulationStatus',
      'gridZone',
    ]

    const ppaDefaultCols: PpaTableField[] = [
      'readableId',
      'name',
      'type',
      'capacity',
      'status',
      'startDate',
    ]

    const spvsDefaultCols: SpvsTableField[] = [
      'readableId',
      'jaName',
      'enName',
      'dcCapacity',
      'totalAssets',
      'updatedAt',
      'createdAt',
    ]

    return {
      ...state,
      balancingGroupListLayout: {
        ...state.balancingGroupsListLayout,
        displayedCols: balancingGroupListDefaultCols,
      },
      reportsListLayout: {
        ...state.reportsListLayout,
        displayedCols: reportsListDefaultCols,
      },
      solarAssetsLayout: {
        ...state.solarAssetsLayout,
        displayedCols: solarAssetsDefaultCols,
      },
      ppasListLayout: {
        ...state.ppasListLayout,
        displayedCols: ppaDefaultCols,
      },
      spvsListLayout: {
        ...state.spvsListLayout,
        displayedCols: spvsDefaultCols,
      },
    }
  },
  // MIGRATION FROM VERSION 2 TO 3
  (state: any) => ({
    ...state,
    solarAssetsLayout: {
      ...state.solarAssetsLayout,
      displayedCols: state.solarAssetsLayout.displayedCols.filter((el: any) => el !== 'investor'),
    },
    settingsStakeholdersActiveTab:
      state.settingsStakeholdersActiveTab === 'stakeholders'
        ? 'offtakers'
        : state.settingsStakeholdersActiveTab,
  }),
  // MIGRATION FROM VERSION 3 TO 4
  (state: any) => ({
    ...state,
    solarAssetActiveTab: 'project',
  }),
  // MIGRATION FROM VERSION 4 TO 5
  (state: any) => ({
    ...state,
    settingsStakeholdersActiveTab:
      state.settingsStakeholdersActiveTab === 'investors'
        ? 'offtakers'
        : state.settingsStakeholdersActiveTab,
  }),
  // MIGRATION FROM VERSION 5 TO 6
  (state: any) => ({
    ...state,
  }),
  // MIGRATION FROM VERSION 6 TO 7
  (state: any) => ({
    ...state,
    balancingGroupsListLayout: {
      sortParam: 'createdAt',
      sortAscending: false,
      displayedCols: ['bgCode', 'name', 'gridZone', 'totalAssets', 'updatedAt', 'createdAt'],
    },
  }),
]
