import { StoreSlice, SettingsStakeholdersTab } from '../types'
import { SETTINGS_STAKEHOLDERS_TABS } from '../constants'

export interface SettingsStakeholdersSlice {
  // TABS
  settingsStakeholdersActiveTab: SettingsStakeholdersTab
  setSettingsStakeholdersActiveTab: (tab: SettingsStakeholdersTab) => void
}
export const createSettingsStakeholdersSlice: StoreSlice<
  SettingsStakeholdersSlice
> = (set, _get) => ({
  // TABS
  settingsStakeholdersActiveTab: SETTINGS_STAKEHOLDERS_TABS[0],
  setSettingsStakeholdersActiveTab: (tab: SettingsStakeholdersTab) =>
    set(() => ({ settingsStakeholdersActiveTab: tab })),
})
