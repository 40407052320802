import { StoreSlice } from '../types'

export interface InviteVerificationSlice {
  inviteVerification: string | null
  setInviteVerification: (code: string) => void
  resetInviteVerification: VoidFunction
}

export const createInviteVerificationSlice: StoreSlice<InviteVerificationSlice> = (set, _get) => ({
  inviteVerification: null,
  setInviteVerification: (code: string) => set({ inviteVerification: code }),
  resetInviteVerification: () => set({ inviteVerification: null }),
})
