
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { NextPageWithLayout } from '@/types'
import { ApolloProvider } from '@apollo/client'
import { Amplify } from 'aws-amplify'
import { ContactSupportModal } from 'common-v2/modals/ContactSupportModal'
import { ToastContainer } from 'common/toasts/ToastContainer'
import { getTensorFonts } from 'fonts'
import { formatUrl } from 'format'
import NProgress from 'next-nprogress-emotion'
import useTranslation from 'next-translate/useTranslation'
import { AppProps } from 'next/app'
import type { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { RouterProvider as AriaRouterProvider, I18nProvider } from 'react-aria'
import 'react-toastify/dist/ReactToastify.min.css'
import { Provider as BalanceProvider } from 'react-wrap-balancer'
import { getConfig } from 'src/aws-config'
import { LangProvider } from 'src/contexts/LangContext'
import { SentryProvider } from 'src/contexts/SentryContext'
import { useApollo } from 'src/providers/apollo'
import 'stylesheets/baseline.css'
import 'stylesheets/calendar.css'
import { makeLocalizedTheme } from 'theme'
import { ThemeProvider } from 'ui'
import { SonnerToastContainer } from 'ui-v2'
import { format } from 'url'

const fonts = getTensorFonts()

Amplify.configure({
  ...getConfig(),
  ssr: true,
})

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout): JSX.Element {
  const router = useRouter()
  const getLayout = Component.getLayout ?? ((page) => page)
  const client = useApollo(pageProps.initialApolloState, {
    idToken: pageProps.idToken,
  })

  const { lang } = useTranslation()
  const locale = lang === 'ja' ? 'ja-JP' : 'en-US'
  const theme = makeLocalizedTheme(lang, {
    fontFamily: fonts.ibmPlexSans.style.fontFamily,
  })

  const useHref = useCallback((href: LinkProps['href']) => {
    if (typeof href === 'string') {
      return href
    }
    return formatUrl(href)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <I18nProvider locale={locale}>
          <SonnerToastContainer />
          <NProgress
            color={theme.rawColors?.primary || '#aaa'}
            options={{ trickleSpeed: 50 }}
            showAfterMs={200}
            spinner={false}
          />
          <SentryProvider>
            <LangProvider>
              <ContactSupportModal />
              <BalanceProvider>
                <AriaRouterProvider
                  navigate={(href, opts) => router.push(href, undefined, opts)}
                  useHref={useHref}
                >
                  {getLayout(<Component {...pageProps} />)}
                </AriaRouterProvider>
              </BalanceProvider>
            </LangProvider>
            <ToastContainer id="default" />
          </SentryProvider>
        </I18nProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  