import {
  GenerationUploadsQuery,
  FileStatusType,
} from 'generated/tensor-api/generated-types'
import { StoreSlice } from '../types'

export const getUploadsStatusUpdate = (
  prev: GenerationUploadsQuery['generationUploads'],
  fileId: string,
  status: FileStatusType
) => {
  const index = prev.findIndex((el) => el.id === fileId)
  if (index === -1) return prev
  const newPrev = [...prev]
  newPrev[index].status.status = status
  return newPrev
}

export interface OperationUploadsSlice {
  operationUploadsLocalFileData: GenerationUploadsQuery['generationUploads']
  addOperationUploadsLocalFileData: (
    data: GenerationUploadsQuery['generationUploads']
  ) => void
  updateOperationUploadsLocalFileStatus: (
    fileId: string,
    status: FileStatusType
  ) => void
  resetOperationUploads: VoidFunction
}

const initialState = {
  operationUploadsLocalFileData: [],
}
export const createOperationUploadsSlice: StoreSlice<OperationUploadsSlice> = (
  set,
  get
) => ({
  ...initialState,
  addOperationUploadsLocalFileData: (data) => {
    set(() => {
      const current = get().operationUploadsLocalFileData
      return {
        operationUploadsLocalFileData: [...current, ...data],
      }
    })
  },
  updateOperationUploadsLocalFileStatus: (fileId, status) => {
    set(() => {
      const current = get().operationUploadsLocalFileData
      return {
        operationUploadsLocalFileData: getUploadsStatusUpdate(
          current,
          fileId,
          status
        ),
      }
    })
  },
  resetOperationUploads: () => set(initialState),
})
