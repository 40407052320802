import { GraphQLErrors } from '@apollo/client/errors'
import { onError } from '@apollo/client/link/error'
import { formatGqlError } from 'apollo-utils'
import { log } from 'logger'
import { isWhitelistedGraphQLError, logSentryError } from 'sentry-utils'
import store, { tensorCloudSessionStore as sessionStore } from 'store'

const handleAuthorizationErrors = (errors: GraphQLErrors) => {
  if (errors.some((error) => error.extensions.code === 'AUTHORIZATION_ERROR')) {
    log.error('Authorization error')
    sessionStore.getState().setIsAuthorized(false)
    store.getState().resetWorkspaceSlice()
  }
}

export const getErrorLink = () =>
  onError(({ graphQLErrors, networkError, ...metadata }) => {
    if (graphQLErrors) {
      handleAuthorizationErrors(graphQLErrors)
      graphQLErrors.forEach((error) => {
        log.debug(formatGqlError(error, metadata))
        if (isWhitelistedGraphQLError(error)) return
        log.error(`[GraphQL error]: ${error.message}`)
        logSentryError(error.message)
      })
    }
    if (networkError) log.error(`[Network error]: ${networkError}`)
  })
