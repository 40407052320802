import { TableLayout } from 'typ'
import { StoreSlice } from '../types'

export type PpaTableField =
  | 'readableId'
  | 'name'
  | 'type'
  | 'capacity'
  | 'startDate'
  | 'term'
  | 'offtaker'
  | 'status'
  | 'totalAssets'
  | 'userUpdatedAt'
  | 'createdAt'

const initialLayout: TableLayout<PpaTableField> = {
  sortParam: 'createdAt',
  sortAscending: false,
  displayedCols: [
    'readableId',
    'name',
    'type',
    'capacity',
    'status',
    'startDate',
  ],
}

export interface PpasListSlice {
  // TABLE LAYOUT
  ppasListLayout: TableLayout<PpaTableField>
  setPpasListLayout: (layout: Partial<TableLayout<PpaTableField>>) => void
}
export const createPpasListSlice: StoreSlice<PpasListSlice> = (set, _get) => ({
  // TABLE LAYOUT

  ppasListLayout: {
    ...initialLayout,
  },
  setPpasListLayout: (layout: Partial<TableLayout<PpaTableField>>) =>
    set((state) => ({
      ppasListLayout: { ...state.ppasListLayout, ...layout },
    })),
})
