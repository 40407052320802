import { useLocale as useAriaLocale } from 'react-aria'
import { Locale } from 'typ'

function isSupportedLocale(locale: string): locale is Locale {
  return locale === 'en' || locale === 'ja'
}

export const useLocale = () => {
  const { locale } = useAriaLocale()

  const normalizedLocale = locale === 'ja-JP' ? 'ja' : locale === 'en-US' ? 'en' : locale
  if (!isSupportedLocale(normalizedLocale)) {
    throw new Error(`Unsupported locale: ${normalizedLocale}`)
  }

  return { locale: normalizedLocale }
}
