import { CSSObject } from 'typ'
import { roundedScrollbars } from './scrollbars'
import { noSelect, srOnly } from './utils'

const noSelectRules = {
  '&::placeholder': {
    ...noSelect,
  },
  '&:disabled': {
    ...noSelect,
  },
}
const inputBase: CSSObject = {
  fontFamily: 'body',
  border: '1px solid',
  borderRadius: 2,
  fontSize: 2,
  py: '6px',
  px: '7px',
  lineHeight: 1.5,
  maxHeight: '2.1875rem',
  minHeight: '2.1875rem',
  transition: 'border-color .1s ease-in-out .05s',
  '&:focus-within': {
    borderColor: 'primaryLight',
    outline: 'none',
  },
  ...noSelectRules,
}

const textareaBase: CSSObject = {
  ...inputBase,
  ...roundedScrollbars,
  width: '100%',
  maxHeight: undefined,
  resize: 'none',
  bg: 'white',
  color: 'text',
  borderColor: 'steel2',
  ...noSelectRules,
}

const textareaAutoSizeBase: CSSObject = {
  ...textareaBase,
  maxWidth: '100%',
  minWidth: '8rem',
  overflowY: 'hidden',
}

const labelBase: CSSObject = {
  fontSize: 2,
  mb: 2,
}

const errorInputBase: CSSObject = {
  borderColor: 'danger',
  color: 'danger',
  '&:focus-within': {
    borderColor: 'dangerShade1',
    outline: 'none',
  },
}
export const forms: Record<string, CSSObject> = {
  hidden: srOnly,
  label: {
    ...labelBase,
    color: 'textGray',
  },
  'label-disabled': {
    ...labelBase,
    color: 'gray3',
  },
  input: {
    ...inputBase,
    borderColor: 'steel1',
    color: 'text',
    bg: 'white',
  },
  'input-error': {
    ...inputBase,
    bg: 'white',
    ...errorInputBase,
  },
  'input-success': {
    ...inputBase,
    borderColor: 'success',
    color: 'success',
  },
  'input-disabled': {
    ...inputBase,
    ...noSelect,
    cursor: 'not-allowed',
    borderColor: 'gray3',
    color: 'gray3',
    bg: 'gray1',
    '&:focus-within': {
      outline: 'none',
    },
    ...noSelectRules,
  },
  textarea: textareaBase,
  'textarea-error': {
    ...textareaBase,
    ...errorInputBase,
  },
  'textarea-autosize': textareaAutoSizeBase,
  'textarea-autosize-error': {
    ...textareaAutoSizeBase,
    ...errorInputBase,
  },
  unstyled: {
    width: '100%',
    border: 'none',
    bg: 'transparent',
    outline: 'none',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 1.5,
    color: 'inherit',
    padding: 0,
    margin: 0,
    ...noSelectRules,
  },
  radio: {
    cursor: 'pointer',
    color: 'steel1',
  },
  'radio-disabled': {
    cursor: 'not-allowed',
    fill: 'gray',
  },
}
