import { TableLayout } from 'typ'
import { StoreSlice } from '../types'

export type ReportsTableField =
  | 'readableId'
  | 'name'
  | 'scope'
  | 'createdAt'
  | 'author'

const initialLayout: TableLayout<ReportsTableField> = {
  sortParam: 'createdAt',
  sortAscending: false,
  displayedCols: ['name', 'scope', 'createdAt', 'author'],
}

export interface ReportsListSlice {
  // TABLE LAYOUT
  reportsListLayout: TableLayout<ReportsTableField>
  setReportsListLayout: (
    layout: Partial<TableLayout<ReportsTableField>>
  ) => void
}

export const createReportsSlice: StoreSlice<ReportsListSlice> = (
  set,
  _get
) => ({
  // TABLE LAYOUT

  reportsListLayout: {
    ...initialLayout,
  },
  setReportsListLayout: (layout: Partial<TableLayout<ReportsTableField>>) =>
    set((state) => ({
      reportsListLayout: { ...state.reportsListLayout, ...layout },
    })),
})
