import {
  CouponPaymentFrequency,
  FipBeneficiary,
  Month,
  PpaStatus,
  PricingScheme,
  SettlementMarket,
  SettlementPricingZone,
  SpvCompanyType,
} from 'generated/tensor-api/generated-types'
import { chartColors } from 'theme'

export const PAGINATED_LIST_SIZES = [10, 20, 40] as const

export const INTRADAY_CHART_SELECTION = ['candlestick', 'confidence-bar'] as const

export const INTRADAY_DATA_OPTIONS = ['high-low-prices', 'contracted-volume'] as const

export const ANALYTICS_MARKETS_TABS = [
  'day-ahead',
  'intraday',
  'imbalance',
  'certificates',
  'capacity',
] as const
export const ANALYTICS_SUPPLY_DEMAND_TABS = ['generation', 'consumption'] as const

export const SETTINGS_STAKEHOLDERS_TABS = [
  'offtakers',
  'epc-providers',
  'om-providers',
  'developers',
] as const

export const SOLAR_ASSET_TABS = ['project', 'simulation', 'operations'] as const

export const CASH_FLOW_AGGREGATION_KEYS = ['individual', 'cumulative'] as const
export const CASH_FLOW_BREAKDOWN_KEYS = ['gen', 'mrv', 'frv', 'ppf', 'opx', 'cpx', 'ncf'] as const

export const CASH_FLOW_KEYS = [
  'gen',
  'mrv',
  'frv',
  'ppf',
  'opx',
  'opf',
  'cpx',
  'ncf',
  'ccf',
] as const

export const ENERGY_CONSUMPTION_API_CODES = ['dem', 'itc', 'phy', 'sol', 'win'] as const

export const ENERGY_CONSUMPTION_COLOR_MAP: Record<
  (typeof ENERGY_CONSUMPTION_API_CODES)[number],
  string
> = {
  dem: chartColors.hi.transparent[1],
  itc: chartColors.hi.transparent[2],
  phy: chartColors.hi.transparent[3],
  sol: chartColors.hi.transparent[4],
  win: chartColors.hi.transparent[5],
}

export const ENERGY_SOURCE_API_CODES = [
  'nuc',
  'the',
  'hyd',
  'sol',
  'win',
  'bio',
  'geo',
  'phy',
  'itc',
] as const

export const ENERGY_SOURCE_COLOR_MAP: Record<(typeof ENERGY_SOURCE_API_CODES)[number], string> = {
  nuc: chartColors.hi.transparent[1],
  the: chartColors.hi.transparent[2],
  hyd: chartColors.hi.transparent[3],
  sol: chartColors.hi.transparent[4],
  win: chartColors.hi.transparent[5],
  bio: chartColors.hi.transparent[6],
  geo: chartColors.hi.transparent[7],
  phy: chartColors.hi.transparent[8],
  itc: chartColors.hi.transparent[9],
}

export const GENERATION_KEYS = ['gen', 'cur'] as const
export const M_Y_RESOLUTION_KEYS = ['monthly', 'yearly'] as const
export const REVENUES_KEYS = ['mrv', 'frv', 'ppf'] as const

export const OPEX_KEYS = ['otr', 'hwr', 'oem', 'asm', 'ins', 'lle', 'dec', 'tax'] as const

export const REGION_API_CODES = [
  'sys',
  'hkd',
  'toh',
  'tok',
  'chb',
  'hok',
  'kan',
  'chg',
  'shi',
  'kyu',
  'oki',
] as const

export const REGION_API_CODES_ALPHABETICAL = [
  'sys',
  'chb',
  'chg',
  'hkd',
  'hok',
  'kan',
  'kyu',
  'oki',
  'toh',
  'shi',
  'tok',
] as const

export const REGIONS_COLOR_MAP: Record<(typeof REGION_API_CODES)[number], string> = {
  chb: chartColors.hi.default[1],
  chg: chartColors.hi.default[2],
  hkd: chartColors.hi.default[3],
  hok: chartColors.hi.default[4],
  kan: chartColors.hi.default[5],
  kyu: chartColors.hi.default[6],
  oki: chartColors.hi.default[7],
  shi: chartColors.hi.default[8],
  sys: chartColors.hi.default[9],
  toh: chartColors.hi.default[10],
  tok: chartColors.hi.default[11],
} as const

export const SPV_LEGAL_ENTITY_FORM_INITIAL_VALUES = {
  companyType: SpvCompanyType.Gk,
  legalEntityNameEn: '',
  legalEntityNameJa: '',
  financialYear: '' as '' | Month,
  companyRegistrationId: '',
  legalEntityCreationDate: '',
}

export const SPV_FINANCIAL_INFORMATION_FORM_INITIAL_VALUES = {
  couponPaymentFrequency: CouponPaymentFrequency.Annual,
  couponPaymentMonth: '' as Month | '',
  couponPayoutPercentage: '',
  loans: [],
  investments: [],
  targetDSCR: '',
}

export const SPV_ASSETS_FORM_INITIAL_VALUES = {
  assets: [],
}

export const NEW_SPV_CREATION_STEPS = ['legal-entity', 'financials', 'assets'] as const

const defaultFlexiblePrice = [{ label: '1', value: '0' }]

export const PPA_ENTITY_FORM_INITIAL_VALUES = {
  name: '',
  // BASIC CONTRACT TERMS
  type: undefined,
  status: PpaStatus.Draft,
  offtaker: '',
  startDate: '',
  term: '10',
  fipBeneficiary: FipBeneficiary.Ipp,
  // PRICING TERMS
  pricingScheme: PricingScheme.Fixed,
  strikePrice: '',
  discountToMarket: '',
  floorPrice: '0',
  capPrice: '',
  floatingShare: '',
  fixedShare: '',
  isFlexiblePricingScheme: false,
  // SETTLEMENT TERMS
  settlementMarket: SettlementMarket.DayAhead,
  settlementPricingZone: SettlementPricingZone.System,
  calculationInterval: '30',
  strikePriceFlexible: defaultFlexiblePrice,
  floorPriceFlexible: defaultFlexiblePrice,
  discountToMarketFlexible: defaultFlexiblePrice,
  capPriceFlexible: defaultFlexiblePrice,
}

export const PPA_ASSETS_FORM_INITIAL_VALUES = {
  assets: [],
}

export const NEW_PPA_CREATION_STEPS = ['ppa', 'assets'] as const

export const MONITORING_DATA_UPLOAD_STEPS = ['asset-selection', 'data-upload'] as const

export const BG_ENTITY_FORM_INITIAL_VALUES = {
  balancingGroupCode: '',
  gridZone: '',
  contractClassificationNumber1: '',
  name: '',
} as const

export const BG_ASSETS_FORM_INITIAL_VALUES = {
  assets: [],
}

export const NEW_BG_CREATION_STEPS = ['bg', 'assets'] as const
