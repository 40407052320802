import { breakList, bytesBreakList, jpBreakList, jpQuantifiers, suffixMap } from './lists'
import { NumberFormatUnit, Options } from './types'
import {
  findBreakIndexAgainstMinValue,
  getCurrencyPrefix,
  getCurrencySuffix,
  getIsJpFormat,
  getMinusSymbol,
  getParsedValue,
  getSafeNumberValue,
  isBelowMin,
  parseValueAgainstMin,
} from './utils'

export const formatNum = (int?: number | string | null, options?: Options): string => {
  if (typeof int === 'undefined' || int === null) return 'N/A'
  const valueAsNumber = Number(int)
  if (Number.isNaN(valueAsNumber)) return 'N/A'
  const optValues = {
    precision: 1,
    showNegatives: true,
    unit: 'int' as NumberFormatUnit,
    long: false,
    locale: 'en',
    forcePrecision: false,
    minValue: null,
    minUnitIndex: 0,
    ...options,
  }
  const absValue = Math.abs(valueAsNumber)
  const isJpFormat = getIsJpFormat(optValues.unit, optValues.locale)
  const isBytes = optValues.unit === 'bytes'
  const suffixes = suffixMap[optValues.unit || 'int']
  const currencyPrefix = getCurrencyPrefix(optValues.unit, optValues.locale)
  const currencySuffix = getCurrencySuffix(optValues.unit, optValues.locale)
  const wrapValue = (value: number, suffix?: string) => {
    const safeValue = getSafeNumberValue(value, optValues.precision, optValues.forcePrecision)
    const isValueBelowMin = isBelowMin(valueAsNumber, optValues.minValue)
    const finalValue = isValueBelowMin
      ? parseValueAgainstMin(valueAsNumber, optValues.minValue)
      : getParsedValue(safeValue, optValues.locale, currencyPrefix, optValues.long)
    const showMinus = optValues.showNegatives && !isValueBelowMin && Number(finalValue) !== 0
    return `${getMinusSymbol(valueAsNumber, showMinus)}${finalValue}${
      suffix ?? ''
    }${currencySuffix}`
  }

  const baseSuffix = suffixes[suffixes.length - 1]

  switch (true) {
    case optValues.long:
      return wrapValue(absValue)
    case isBytes: {
      const i = findBreakIndexAgainstMinValue(absValue, optValues.minUnitIndex, bytesBreakList)
      return wrapValue(absValue / (bytesBreakList[i] || 1), suffixes[i] || baseSuffix)
    }
    case isJpFormat: {
      const i = findBreakIndexAgainstMinValue(absValue, optValues.minUnitIndex, jpBreakList)
      return wrapValue(absValue / (jpBreakList[i] || 1), jpQuantifiers[i] || baseSuffix)
    }
    default: {
      const i = findBreakIndexAgainstMinValue(absValue, optValues.minUnitIndex, breakList)
      return wrapValue(absValue / (breakList[i] || 1), suffixes[i] || baseSuffix)
    }
  }
}
