import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { tensorCloudMigrations } from './migrations'
import {
  TensorCloudPersistedSlice,
  TensorCloudSessionStoreSlice,
  createPersistedStoreSlice,
  createSessionStoreSlice,
} from './root-slices'

const storeBase = create(
  persist(createPersistedStoreSlice, {
    name: 'tensor-cloud-storage',
    version: 7,
    // SEE https://github.com/pmndrs/zustand/issues/984 FOR AN EXAMPLE ON THE CONCEPT
    // BEHIND MIGRATIONS THROUGH MULTIPLE VERSIONS
    migrate: (persistedState, version) => {
      let state = persistedState

      for (let i = version; i < tensorCloudMigrations.length; i++) {
        state = tensorCloudMigrations[i](state)
      }

      return state as TensorCloudPersistedSlice
    },
  })
)
const tensorCloudSessionStoreBase = create(createSessionStoreSlice)

// STORE HOOKS
export const useTensorCloudStore = createSelectorHooks<TensorCloudPersistedSlice>(storeBase)
export const useTensorCloudSessionStore = createSelectorHooks<TensorCloudSessionStoreSlice>(
  tensorCloudSessionStoreBase
)
