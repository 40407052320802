const BASE_PATH = 'tensorenergy.jp'

// WEBPAGE
export const WEBPAGE_URL = 'https://www.tensorenergy.jp'
// HTTPS
export const LOCAL_BASE_URL = 'http://localhost:3000'
export const DEV_BASE_URL = `https://development.${BASE_PATH}`
export const STAGING_BASE_URL = `https://staging.${BASE_PATH}`
export const PROD_BASE_URL = `https://cloud.${BASE_PATH}`
export const TENSOR_WEB = `https://www.${BASE_PATH}`
export const DOCS_DEV_BASE_URL = `https://docs.development.${BASE_PATH}`
export const DOCS_PROD_BASE_URL = `https://docs.${BASE_PATH}`

// AUTH
export const LOGIN_URL = '/login'
export const LOGOUT_URL = '/logout'
export const LOGGED_USERS_INDEX_URL = '/assets/solar'
// NEW FEATURES
export const NEW_FEATURES_URL = '/new-features'
// WIP
export const WIP_URL = '/work-in-progress'

// ASSETS
export const ASSETS_BASE_URL = '/assets'
export const SOLAR_ASSETS_BASE_URL = '/assets/solar'
export const SOLAR_ASSETS_VIEW_URL = `${SOLAR_ASSETS_BASE_URL}/view`
export const SOLAR_ASSETS_EDIT_URL = `${SOLAR_ASSETS_BASE_URL}/edit`
export const SOLAR_ASSETS_CHAIN_EDIT_URL = `${SOLAR_ASSETS_BASE_URL}/chain-edit`
export const SOLAR_ASSETS_NEW_URL = `${SOLAR_ASSETS_BASE_URL}/new`
export const ASSETS_EDIT_BATTERY_PLAN = `${ASSETS_BASE_URL}/edit-battery-schedule`
export const ASSETS_UPLOADS_URL = `${ASSETS_BASE_URL}/uploads`
export const ASSETS_UPLOADS_METER_DATA_URL = `${ASSETS_UPLOADS_URL}/meter-data`
export const ASSETS_UPLOADS_MONITORING_DATA_URL = `${ASSETS_UPLOADS_URL}/monitoring-data`

// PPAS
export const PPAS_BASE_URL = '/ppas'
export const PPAS_VIEW_URL = `${PPAS_BASE_URL}/view`
export const PPAS_LIST_URL = `${PPAS_BASE_URL}/list`
export const PPAS_NEW_URL = `${PPAS_BASE_URL}/new`
export const PPAS_EDIT_URL = `${PPAS_BASE_URL}/edit`
export const PPAS_ASSETS_EDIT_URL = `${PPAS_BASE_URL}/assets-edit`

// SPVS
export const SPVS_BASE_URL = '/spvs'
export const SPVS_VIEW_URL = `${SPVS_BASE_URL}/view`
export const SPVS_LIST_URL = `${SPVS_BASE_URL}/list`
export const SPVS_NEW_URL = `${SPVS_BASE_URL}/new`
export const SPVS_EDIT_URL = `${SPVS_BASE_URL}/edit`
export const SPVS_ASSETS_EDIT_URL = `${SPVS_BASE_URL}/assets-edit`

// BALANCING GROUPS
export const BALANCING_GROUPS_BASE_URL = `/balancing-groups`
export const BALANCING_GROUPS_LIST_URL = `${BALANCING_GROUPS_BASE_URL}/list`
export const BALANCING_GROUPS_VIEW_URL = `${BALANCING_GROUPS_BASE_URL}/view`
export const BALANCING_GROUPS_NEW_URL = `${BALANCING_GROUPS_BASE_URL}/new`
export const BALANCING_GROUPS_EDIT_URL = `${BALANCING_GROUPS_BASE_URL}/edit`
export const BALANCING_GROUPS_ASSETS_EDIT_URL = `${BALANCING_GROUPS_BASE_URL}/assets-edit`

// NOTIFICATIONS
export const NOTIFICATIONS_BASE_URL = '/notifications'
export const NOTIFICATIONS_INBOX_URL = `${NOTIFICATIONS_BASE_URL}/inbox`

// PLANNING
export const PLANNING_BASE_URL = '/planning'

// SCENARIOS
export const SCENARIOS_BASE_URL = `${PLANNING_BASE_URL}/scenarios`
export const SCENARIOS_LIST_URL = `${SCENARIOS_BASE_URL}/list`
export const SCENARIOS_NEW_URL = `${SCENARIOS_BASE_URL}/new`
export const SCENARIOS_EDIT_URL = `${SCENARIOS_BASE_URL}/edit`
export const SCENARIOS_CHAIN_EDIT_URL = `${SCENARIOS_BASE_URL}/chain-edit`

// REPORTS
export const REPORTS_BASE_URL = `${PLANNING_BASE_URL}/reports`
export const REPORTS_VIEW_URL = `${REPORTS_BASE_URL}/view`
export const REPORTS_LIST_URL = `${REPORTS_BASE_URL}/list`
export const REPORTS_NEW_URL = `${REPORTS_BASE_URL}/new`

// FORECASTING
export const FORECASTING_BASE_URL = '/forecasting'
export const FORECASTING_LIST_URL = `${FORECASTING_BASE_URL}/list`

// BALANCING

export const BALANCING_BASE_URL = '/balancing'
export const BALANCING_OCCTO_REPORTING_URL = `${BALANCING_BASE_URL}/occto-reporting`
export const BALANCING_HISTORY_URL = `${BALANCING_BASE_URL}/history`

// OPERATIONS
export const OPERATIONS_BASE_URL = '/operations'

// ANALYTICS
export const ANALYTICS_BASE_URL = '/analytics'
export const ANALYTICS_MARKETS_URL = `${ANALYTICS_BASE_URL}/markets`
export const ANALYTICS_SUPPLY_DEMAND_URL = `${ANALYTICS_BASE_URL}/supply-demand`

// SETTINGS
export const SETTINGS_BASE_URL = '/settings'
export const SETTINGS_ACCOUNT_URL = `${SETTINGS_BASE_URL}/account` //TODO: Delete when done with migration
export const SETTINGS_NOTIFICATIONS_URL = `${SETTINGS_BASE_URL}/notifications`
export const SETTINGS_GENERAL_URL = `${SETTINGS_BASE_URL}/general`
export const SETTINGS_MEMBERS_URL = `${SETTINGS_BASE_URL}/members`
export const SETTINGS_STAKEHOLDERS_URL = `${SETTINGS_BASE_URL}/stakeholders`
export const SETTINGS_BILLING_URL = `${SETTINGS_BASE_URL}/billing`
export const SETTINGS_OPERATIONS_URL = `${SETTINGS_BASE_URL}/operations`
export const SETTINGS_APIS_URL = `${SETTINGS_BASE_URL}/apis`
export const SETTINGS_PERSONAL_URL = `${SETTINGS_BASE_URL}/personal`
export const SETTINGS_PREFERENCES_URL = `${SETTINGS_BASE_URL}/preferences`
export const SETTINGS_SECURITY_URL = `${SETTINGS_BASE_URL}/security`
export const SETTINGS_WORKSPACE_URL = `${SETTINGS_BASE_URL}/organization` //TODO: Delete when done with migration

// TRADING
export const TRADING_BASE_URL = '/trading'
export const TRADING_JEPX_URL = `${TRADING_BASE_URL}/jepx`
export const TRADING_HISTORY_URL = `${TRADING_BASE_URL}/history`
export const TRADING_CUSTOM_POSITION_URL = `${TRADING_JEPX_URL}/custom-position`

// PROFILE
export const PROFILE_URL = '/profile'

// SUPPORT
export const TERMS_OF_SERVICE = '/legal/terms-of-use'
export const PRIVACY_POLICY = '/legal/privacy-policy'
export const SUPPORT_EMAIL = 'support@tensorenergy.jp'
export const PWD_RESET = '/pwd-recovery'
export const PWD_CONFIRMATION = '/pwd-confirmation'

// DOCS
export const DOCS_ASSETS = '/reference/assets'
export const DOCS_ASSETS_SOLAR_LIST = `${DOCS_ASSETS}/solar-asset-list`
export const DOCS_ASSETS_SOLAR_SETTINGS = `${DOCS_ASSETS}/solar-asset-settings`
export const DOCS_ASSETS_DATA_UPLOAD = `${DOCS_ASSETS}/data-uploads`
export const DOCS_SCENARIOS = '/reference/scenarios'
export const DOCS_SCENARIOS_SETTINGS = `${DOCS_SCENARIOS}/scenario-settings`
export const DOCS_SCENARIOS_INTRO = `${DOCS_SCENARIOS}/introduction`
export const DOCS_BALANCING = '/reference/balancing'
export const DOCS_BALANCING_GROUPS = `${DOCS_BALANCING}/balancing-groups`
export const DOCS_BALANCING_UPLOADS = `${DOCS_BALANCING}/data-uploads`
export const DOCS_BALANCING_FORECASTS = `${DOCS_BALANCING}/forecasts`
export const DOCS_OCCTO_REPORTING = `${DOCS_BALANCING}/occto-reporting`
export const DOCS_PPAS = '/reference/ppas'
export const DOCS_PPAS_SETTINGS = `${DOCS_PPAS}/ppa-settings`
export const DOCS_PPAS_VIEW = `${DOCS_PPAS}/ppa-view`
export const DOCS_SPVS = '/reference/spvs'
export const DOCS_SPVS_INTRODUCTION = `${DOCS_SPVS}/introduction`
export const DOCS_SPVS_SETTINGS = `${DOCS_SPVS}/spv-settings`
export const DOCS_TRADING = '/reference/trading'
export const DOCS_TRADING_JEPX = `${DOCS_TRADING}/jepx-trading`
export const DOCS_REPORTS = '/reference/reports'
export const DOCS_REPORTS_INTRODUCTION = `${DOCS_REPORTS}/introduction`
export const DOCS_REPORTS_CREATION = `${DOCS_REPORTS}/creating-reports`
export const DOCS_REPORTS_SHARE = `${DOCS_REPORTS}/sharing-reports`
export const DOCS_REPORTS_VIEW = `${DOCS_REPORTS}/report-page`
export const DOCS_SETTINGS = '/reference/settings'

// API ROUTES
export const TENSOR_CLOUD_CONTACT_US_API_URL = '/api/contact-us'
