import clsx from 'clsx'
import { FormEventHandler, forwardRef } from 'react'
import { Dialog as AriaDialog, DialogProps as AriaDialogProps } from 'react-aria-components'
import { isDefined } from 'typeguards'
import { elementIsNotInDialogWhitelist } from '../../utils'
import { Box } from '../box'
import { Flex } from '../flex'
import { Grid } from '../grid'
import { Modal } from '../modal'
import { Text } from '../text'
import { buttonsWrapperCx, dialogCx, dialogWrapperCx } from './Dialog.css'
import { ControlledDialogProps, DialogProps } from './types'

type DialogContentsProps = (DialogProps | ControlledDialogProps) & {
  buttons: AriaDialogProps['children']
  close: VoidFunction
  isOpen: boolean
}

export const DialogContents = forwardRef<HTMLDivElement, DialogContentsProps>(
  (
    {
      isDismissable,
      onOpenChange,
      isOpen,
      className,
      title,
      description,
      children,
      contentLayout = 'default',
      buttons,
      close,
      onSubmit,
      ...props
    },
    ref
  ) => {
    const isForm = isDefined(onSubmit)
    const handleSubmit: FormEventHandler<HTMLElement> = (e) => {
      e.preventDefault()
      onSubmit?.(close)
    }
    return (
      <Modal
        className={dialogWrapperCx}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        shouldCloseOnInteractOutside={elementIsNotInDialogWhitelist}
        isDismissable={isDismissable}
      >
        <AriaDialog {...props} className={clsx([dialogCx, className])} aria-label={title} ref={ref}>
          <Flex
            direction="column"
            rowGap="1200"
            as={isForm ? 'form' : 'div'}
            onSubmit={handleSubmit}
          >
            <Grid py="000" px="900">
              <Text variant="headingSm" color="colorsTextNeutralDefaultHighContrast" role="heading">
                {title}
              </Text>
              {isDefined(description) && (
                <Text variant="paragraphMd" color="colorsTextNeutralDefaultLowContrast">
                  {description}
                </Text>
              )}
            </Grid>
            {children && (
              <Box px={contentLayout === 'insert' ? '000' : '900'}>
                {typeof children === 'function' ? children({ close }) : children}
              </Box>
            )}
            <div className={buttonsWrapperCx}>
              {typeof buttons === 'function' ? buttons({ close }) : buttons}
            </div>
          </Flex>
        </AriaDialog>
      </Modal>
    )
  }
)

DialogContents.displayName = 'DialogContents'
