import Link from 'next/link'
import { forwardRef } from 'react'
import { TestProps } from 'typ'
import { IconSize } from 'vanilla-extract-config'
import { useLocale } from '../../hooks'
import { HelpIcon } from '../../icons'
import { iconWrapperCx } from './DocsLink.css'

export interface DocsLinkProps extends TestProps {
  href: string
  size?: IconSize
  tabIndex?: number
}

const helpIconLabel = {
  en: 'Help',
  ja: 'ヘルプ',
}

export const DocsLink = forwardRef<HTMLAnchorElement, DocsLinkProps>(
  ({ href, size = 'sm', ...props }, ref) => {
    const { locale } = useLocale()
    return (
      <Link
        ref={ref}
        aria-label={helpIconLabel[locale]}
        href={href}
        className={iconWrapperCx}
        target="_blank"
        rel="noreferrer"
        {...props}
      >
        <HelpIcon size={size} />
      </Link>
    )
  }
)

DocsLink.displayName = 'DocsLink'
