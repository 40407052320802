import { FC } from 'react'
import { iconSprinkles } from 'vanilla-extract-config'
import { BaseIconWrapper } from './common/BaseIconWrapper'
import { SvgIconProps } from './types'

export const HelpIcon: FC<SvgIconProps> = ({ ...props }) => (
  <BaseIconWrapper {...props} data-testid="help-icon">
    <circle
      cx="8"
      cy="8"
      r="6.5"
      className={iconSprinkles({ stroke: 'inherit', fill: 'transparent' })}
    />
    <path
      d="M8.60018 11.1823C8.60018 11.5136 8.33155 11.7823 8.00018 11.7823C7.66881 11.7823 7.40018 11.5136 7.40018 11.1823C7.40018 10.8509 7.66881 10.5823 8.00018 10.5823C8.33155 10.5823 8.60018 10.8509 8.60018 11.1823Z"
      className={iconSprinkles({ fill: 'inherit', stroke: 'transparent' })}
    />
    <path
      d="M6.5 6C6.5 5 7 4.5 8 4.5C9 4.5 10 5 10 6.49997C10 7.99994 8 7.5 8 8.49997V9.49997"
      className={iconSprinkles({ stroke: 'inherit', fill: 'transparent' })}
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </BaseIconWrapper>
)
