import clsx from 'clsx'
import { FC } from 'react'
import { TestProps } from 'typ'
import { iconSprinkles } from 'vanilla-extract-config'
import { SvgIconProps } from '../types'
import { iconCx } from './Icon.css'

export interface BaseIconWrapperProps extends SvgIconProps, TestProps, React.PropsWithChildren {}
export const BaseIconWrapper: FC<BaseIconWrapperProps> = ({
  children,
  activeColor,
  className,
  color = 'inherit',
  size = 'md',
  ...props
}) => (
  <svg
    className={clsx([
      iconSprinkles({
        size,
        fill: { default: color, hover: activeColor || color, focus: activeColor || color },
        stroke: { default: color, hover: activeColor || color, focus: activeColor || color },
      }),
      iconCx,
      className,
    ])}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={props['data-testid']}
    data-cy={props['data-cy']}
    aria-hidden={true}
  >
    {children}
  </svg>
)
